<template id="login">
    <div class="md-min-width card m-5">
        <div><img style="width: 80px !important;" alt="icono corporativo" src="@/assets/logo.svg"></div>
            <h5>Arbol Gauss Control</h5>

            <span v-if="FROM_TVP_SSO">
                    <p class="subtitle is-7 has-text-dark">CONECTANDO SSO</p>
                </span>
                <span v-else>
            <div class="field mt-3">
                <h5 for="username2">Nombre de usuario</h5>
                <InputText id="username2" type="username" aria-describedby="username2-help"  :class="{'p-invalid': error.credentials}" v-model="username"/>
                <h6 v-if="error.credentials" id="username2-help" :class="{'p-error': error.credentials}">Usuario invalido.</h6>
            </div>
            <div class="field">
                <h5 for="username2">Contraseña</h5>
                <Password v-model="password" toggleMask/>
            </div>
            <buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-users" label="Ingresar" @click.prevent="logIn()"/>
        </span>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import AuthService from '@/service/AuthService';

    let LoginPage = {};
    (function () {
        this.name = 'login-page';
        // let _self = null;

        this.created = function () {
            this.$store.dispatch('form/error', {credentials: false});
             this.checkSsoToken();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                username: '',
                password: '',
                FROM_TVP_SSO: false,
            }
        };

        
        this.methods = {
            checkSsoToken() {
                const s = this.$route.query["sso-token"];
                if (s) {
                    this.FROM_TVP_SSO = true;
                    const session = JSON.parse(window.atob(s));
                    //this.$store.dispatch('auth2/set', session);
                    this.$store.dispatch('auth/set-access-token', session.access_token);
                      this.$store.dispatch('auth/set-refresh-token', session.refresh_token);
                    AuthService.getUserDetailToken()
                      .then((response) => this.onAuthentication(response))
                      .catch((err) => {
                        console.log(err);
                        this.FROM_TVP_SSO = false;
                      })
                }
            },
            onAuthentication(response) {
                this.$store.dispatch('auth/set-user', response[1].employee_id);
                this.$store.dispatch('auth/set-groups', response[0]);

                if(this.isInGroups('gauss_admin', response[0]) === false) {
                    let result = {isError: true, message: 'Accedo denegado'};
                    this.$store.dispatch('form/result', result);
                    this.$router.push({name: 'logout'});
                }else{
                    this.$router.replace({name: 'home'});
                }

                this.$store.dispatch('form/submit', false);
            },
            isInGroups: function (groupName, groups) {
                for (var i = 0; i < groups.length; i++) {
                        if (groups[i].name === groupName) {
                            return true;
                        }
                }
                return false;
            },
            logIn() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    username: false,
                    password: false,
                    credentials: false
                });
                
                if (this.username === null || this.username.trim() === '') {
                    this.$store.dispatch('form/error', {username: true});
                }
                
                if (this.password === null || this.password.trim() === '') {
                    this.$store.dispatch('form/error', {password: true});
                }
            
                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }
               
                this.$store.dispatch('auth/logout');
               
                AuthService.log_in(this.username, this.password)
                    .then(response => {
                      this.$store.dispatch('auth/set-access-token', response.data.access_token);
                      this.$store.dispatch('auth/set-refresh-token', response.data.refresh_token);
                       AuthService.getUserDetail(this.username, this.password)
                            .then(response2 => {
                                this.$store.dispatch('auth/set-user', response2[1].employee_id);
                                this.$store.dispatch('auth/set-groups', response2[0]);
                                this.$router.replace({name: 'home'}); 
                                this.$store.dispatch('form/submit', false);
                            }).catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch(err => {
                      if (err.response.status === 400) {
                        this.$store.dispatch('form/error', {credentials: true});
                        this.$store.dispatch('form/submit', false);
                      }
                    });
                    
            }
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states'
            }),
        };
        this.watch = {
            username(newValue){
                this.username = newValue.toLowerCase();
            }
        };
        this.props = [];
    }).apply(LoginPage);

    export default LoginPage
</script>
