import axios from "axios";

export default {

    new(mc) {
        const url = `${process.env.VUE_APP_API_GC_URL}/new`;
        let data = {managment_control: mc}
        return axios.post(url, data)
    },


    getAllByMax(max){
        const url = `${process.env.VUE_APP_API_GC_URL}/get-last-managment-controls`;
        let data = {max: max}
        return axios.post(url, data)
    },

    getAllManagmentControl(filte) {
        const url = `${process.env.VUE_APP_API_GC_URL}/get-all-managment-control`;
        let data = {filter: filte}
        return axios.post(url, data)
    },

    getAllTypes() {
        const url = `${process.env.VUE_APP_API_GC_URL}/all-types`;
        let data = {}
        return axios.post(url, data)
    },

    gettAllSubTypebyType(id) {
        const url = `${process.env.VUE_APP_API_GC_URL}/all-subtypes-by-type`;
        let data = {type_id: id}
        return axios.post(url, data)
    },

    gettAllObsOne(type_id, subtype_id) {
        const url = `${process.env.VUE_APP_API_GC_URL}/all-obs-one`;
        let data = {type_id: type_id, subtype_id: subtype_id}
        return axios.post(url, data)
    },

    gettAllObsTwo(type_id, subtype_id, obsone_id) {
        const url = `${process.env.VUE_APP_API_GC_URL}/all-obs-two`;
        let data = {type_id: type_id, subtype_id: subtype_id, obsone_id:obsone_id}
        return axios.post(url, data)
    },

    gettAllObsThree(type_id, subtype_id, obsone_id, obstwo_id, is_efective) {
        const url = `${process.env.VUE_APP_API_GC_URL}/all-obs-three`;
        let data = {type_id: type_id, subtype_id: subtype_id, obsone_id:obsone_id, obstwo_id: obstwo_id, is_efective: is_efective}
        return axios.post(url, data)
    },


}
