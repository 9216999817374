import { createWebHistory, createRouter } from "vue-router";
import Login from '../views/LoginPage';
import Home from '../views/HomePage';
import NewManagmentControl from '../views/NewManagmentControl';
import GeneralManagmentControl from '../views/GeneralManagmentControl';

const routes = [
  {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },
    {
        path: '/home',
        alias: ['/home'],
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/new-managment-control',
        alias: ['/new-managment-control'],
        name: 'new-managment-control',
        component: NewManagmentControl,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },
    {
        path: '/general-managment-control',
        alias: ['/general-managment-control'],
        name: 'general-managment-control',
        component: GeneralManagmentControl,
        meta: {
            requiresAuth: true,
            module: 'home'
        }
    },

    {
        name: 'logout',
        path: '/logout',
        redirect: to => {
            console.log(to);
            return {name: 'login'};
        },
        meta: {
            requiresAuth: false,
            module: 'authentication'
        }
    },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

routes.map((route) => {
    if (!route.meta) route.meta = {};
    if (route.meta.requiresAuth !== false)
        route.meta.requiresAuth = true;
});

export default router
