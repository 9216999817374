<template id="home">
    <div class="card p-fluid">

<h5 class="text-black">Gestion GAUSS CONTROL</h5>

    <div class="grid">
            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Gestion General" @click.prevent="goTo('general-managment-control')"/>
            </div>

            <div class="col-12 md:col-6 lg:col-4">
                <buttonp class="w-6 mt-3 p-button-warning" label="Nuevo Ingreso" @click.prevent="goTo('new-managment-control')"/>
            </div>
    </div>
        
        <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    

    import {mapGetters} from 'vuex';
    


    let HomePage = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {

        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               
            }
        };
        this.methods = {
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = [];
    }).apply(HomePage);

    export default HomePage
</script>
