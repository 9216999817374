<template id="home">
    <div class="card p-fluid">

<h5 class="text-black">Gestion Gauss Control</h5>

    <div class="grid">


            <div class="col-12 md:col-12 lg:col-3">
                
            </div> 

            <div class="col-12 md:col-12 lg:col-6">
               <buttonp class="w-6 mt-3 p-button-warning" label="Obtener Razon y Gestion" @click.prevent="goTo('new-managment-control')"/>
            </div>

            <div class="col-12 md:col-12 lg:col-3">
                
            </div> 


            <div class="col-12 md:col-12 lg:col-3">
                
            </div> 
             <div class="col-12 md:col-12 lg:col-2">
                <span class="ml-4">Maximo de Registros</span><br /> <InputText class="mt-3 ml-6" id="maximo" type="text" v-model="maximo"/>
            </div>
            <div class="col-12 md:col-12 lg:col-4">
                <br />
                <buttonp class="w-6 mt-3 p-button-warning" label="Buscar" @click.prevent="buscar()"/>
            </div> 

            <div class="col-12 md:col-12 lg:col-3">
                
            </div> 

           


            <div class="col-12 md:col-12 lg:col-12">
                    <DataTable :value="report" :paginator="true" class="p-datatable-sm" :rows="10"
                    dataKey="id" :rowHover="true" v-model:selection="selectedReportRow" v-model:filters="filters" filterDisplay="menu" :loading="loading"
                    paginatorTemplate="PrimeraPagina PaginaPrevia Pagina SiguientePagina PaginaAnterior PaginaActual CantidadFilas" :rowsPerPageOptions="[10,25,50]"
                    currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} gestiones"
                    :globalFilterFields="['manage_control_id','supervisor', 'driver']" responsiveLayout="scroll">
                    <template #header>
                        <div class="flex justify-content-center align-items-center">
                            <h5 class="m-2">Busqueda</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Palabra Clave" />
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        No se encontraron datos.
                    </template>
                    <template #loading>
                        Cargando datos. Please wait.
                    </template>
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="manage_control_id" header="ID" sortable style="min-width: 14rem">
                        <template #body="{data}">
                            {{data.manage_control_id}}
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by id"/>
                        </template>
                    </Column>
                    <Column field="event_date" header="Hora Suseso" sortable dataType="date" style="min-width: 8rem">
                        <template #body="{data}">
                            {{formatDate(data.event_date)}}
                        </template>
                        <template #filter="{filterModel}">
                            <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                        </template>
                    </Column>
                    <Column field="management_date" header="Hora gestion" sortable dataType="date" style="min-width: 8rem">
                        <template #body="{data}">
                            {{formatDate(data.management_date)}}
                        </template>
                        <template #filter="{filterModel}">
                            <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                        </template>
                    </Column>
                    <Column field="supervisor" header="Supervisor" sortable filterMatchMode="supervisor" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.supervisor}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot supervisor"/>
                        </template>
                    </Column>
                    <Column header="Conductor" sortable filterField="driver" sortField="driver" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.driver}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>
                     <Column header="Vehiculo" sortable filterField="vehicle" sortField="vehicle" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.vehicle}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>

                    <Column header="Tipo" sortable filterField="type_control" sortField="type_control" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.type_control}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>

                    <Column header="SubTipo" sortable filterField="sub_type_control" sortField="sub_type_control" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.sub_type_control}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>

                    <Column header="Observacion 1" sortable filterField="observation_one" sortField="observation_one" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.observation_one}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>

                    <Column header="Observacion 2" sortable filterField="observation_two" sortField="observation_two" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.observation_two}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>

                    <Column header="Observacion 3" sortable filterField="observation_three" sortField="observation_three" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
                        <template #body="{data}">
                            <!--img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" style="vertical-align: middle" /-->
                            <span class="image-text">{{data.observation_three}}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar pot conductor"/>
                        </template>
                    </Column>

                </DataTable>
            </div> 

    </div>
        
        <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    
    import {mapGetters} from 'vuex';
    import {FilterMatchMode,FilterOperator} from 'primevue/api';

    import ManagmentControlService from '@/service/ManagmentControlService';
    
    let GeneralManagmentControl = {};
    (function () {
        this.name = 'gestion-gauss-control-page';
        // let _self = null;

        this.created = function () {
            this.buscar();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
                report: [],
                maximo: 500,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'manage_control_id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'supervisor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                    'driver': {value: null, matchMode: FilterMatchMode.IN},
                    'vehicle': {value: null, matchMode: FilterMatchMode.IN},
                    'reason': {value: null, matchMode: FilterMatchMode.IN},
                    'management': {value: null, matchMode: FilterMatchMode.IN},
                    'type_control': {value: null, matchMode: FilterMatchMode.IN},
                    'sub_type_control': {value: null, matchMode: FilterMatchMode.IN},
                    'observation_one': {value: null, matchMode: FilterMatchMode.IN},
                    'observation_two': {value: null, matchMode: FilterMatchMode.IN},
                    'event_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                    'management_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                    'observation_three': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
                    'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
                },
                loading: true,
            }
        };
        this.methods = {
            buscar(){
                this.report = [];
                 ManagmentControlService.getAllByMax(this.maximo).then((response) => {
                        this.report = response.data.data;
                        this.loading = false;
                }).catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.$store.dispatch('form/submit', false);
                });
            },
            formatDate(value) {
                    return value;
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        
        this.props = [];
    }).apply(GeneralManagmentControl);

    export default GeneralManagmentControl
</script>
