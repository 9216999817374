<template id="home">
    <div class="card p-fluid">

    <h5 class="text-black">Nueva Gestion Gauss Control</h5>

    <div class="grid m-3">
            
            <div class="col-12 md:col-12 lg:col-4">
            </div> 
            <div class="col-12 md:col-12 lg:col-4">
                <autoComplete v-model="selectedSupervisor" field="first_name1" :suggestions="filteredSupervisorMultiple" @complete="searchSupervisorMultiple($event)" placeholder="Seleccione supervisor"></autoComplete>
            </div> 
             <div class="col-12 md:col-12 lg:col-4">
            </div> 

             <div class="col-12 md:col-12 lg:col-4">
            </div> 
            <div class="col-12 md:col-12 lg:col-4">
                <autoComplete v-model="selectedDriver" field="first_name1" :suggestions="filteredDriverMultiple" @complete="searchDriversMultiple($event)" placeholder="Seleccione conductor"></autoComplete>
            </div> 
             <div class="col-12 md:col-12 lg:col-4">
            </div> 

             <div class="col-12 md:col-12 lg:col-4">
            </div> 
            <div class="col-12 md:col-12 lg:col-4">
                <autoComplete v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Seleccione vehiculo"></autoComplete>
            </div> 
             <div class="col-12 md:col-12 lg:col-4">
            </div> 

            <div class="col-12 md:col-12 lg:col-4">
            </div> 
            <div class="col-12 md:col-12 lg:col-4">
                <h5 class="text-black mt-3">Dia y hora Evento</h5>
                <Calendar id="event_date" v-model="event_date" :showTime="true" :showSeconds="false" dateFormat="dd/mm/yy" />
            </div> 
            <div class="col-12 md:col-12 lg:col-4">
            </div>
            
            <div class="col-12 md:col-12 lg:col-4">
              <h5 class="text-black mt-3">Paso 1</h5>
                <h5 class="text-black mt-3">Tipo Evento</h5>
                <Dropdown id="dropDocumentation" v-model="selectedType" :options="types" optionLabel="Name" :filter="false" placeholder="Seleccione tipo evento" :showClear="true">
                  <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
                </template>
                </Dropdown>
                <h6 v-if="error.type_oblig" class="p-error">Seleccion tipo obligatoria.</h6>
            </div> 

             <div class="col-12 md:col-12 lg:col-4" v-if="selectedType != null">
              <h5 class="text-black mt-3">Paso 2</h5>
                <h5 class="text-black mt-3">Subtipo</h5>
                <Dropdown id="dropDocumentation" v-model="selectedSubtype" :options="subtypes" optionLabel="Name" :filter="false" placeholder="Seleccione subtipo evento" :showClear="true">
                  <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
                </template>
                </Dropdown>
                <h6 v-if="error.subtype_oblig" class="p-error">Seleccion subtipo obligatoria.</h6>
            </div> 

            <div class="col-12 md:col-12 lg:col-4" v-if="selectedSubtype != null">
              <h5 class="text-black mt-3">Paso 3</h5>
                <h5 class="text-black mt-3">Observacion 1</h5>
                <Dropdown id="dropDocumentation" v-model="selectedObsOne" :options="obs1" optionLabel="Name" :filter="false" placeholder="Seleccione observacion uno" :showClear="true">
                  <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
                </template>
                </Dropdown>
                <h6 v-if="error.obs1_oblig" class="p-error">Seleccion observacion1 obligatoria.</h6>
            </div> 

            <div class="col-12 md:col-12 lg:col-4" v-if="selectedObsOne != null">
              <h5 class="text-black mt-3">Paso 4</h5>
                <h5 class="text-black mt-3">Observacion 2</h5>
                <Dropdown id="dropDocumentation" v-model="selectedObsTwo" :options="obs2" optionLabel="Name" :filter="false" placeholder="Seleccione observacion dos" :showClear="true">
                  <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
                </template>
                </Dropdown>
                <h6 v-if="error.obs2_oblig" class="p-error">Seleccion observacion2 obligatoria.</h6>
            </div> 

            <div class="col-12 md:col-12 lg:col-4" v-if="selectedObsTwo != null">
               
                <h5 class="text-black mt-3">Paso 5</h5>
                <h5 class="text-black">Efectivo / Descartada</h5>
                    <div style="width: 100%" class="m-2">
                        <Checkbox id="binary" v-model="is_efective" :binary="true" />
                        <label for="binary">Efectivo</label>
                        <Checkbox id="binary" v-model="is_discard" :binary="true" />
                        <label for="binary">Descarta</label>
                        <br />
                    </div>
</div>

<div class="col-12 md:col-12 lg:col-4" v-if="selectedObsTwo != null && (is_efective == true || is_discard == true)">                <h5 class="text-black mt-3">Paso 6</h5>
                 <h5 class="text-black mt-3">Observacion 3</h5>
                <Dropdown v-if="is_efective !== null" id="dropDocumentation" v-model="selectedObsThree" :options="obs3" optionLabel="Name" :filter="false" placeholder="Seleccione observacion tres" :showClear="true">
                  <template #value="slotProps">
                      <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                          <span>
                            {{slotProps.value.name}}
                          </span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-supervisors-option">
                    <span>
                          {{slotProps.option.name}}</span>
                  </div>
                </template>
                </Dropdown>
                <h6 v-if="error.obs3_oblig" class="p-error">Seleccion observacion3 obligatoria.</h6>
            </div> 

            <div class="col-12 md:col-12 lg:col-12">
                <buttonp class="w-6 mt-3 p-button-warning" label="Obtener Razon y Gestion" @click.prevent="createNew()"/>
            </div> 


    </div>

    <Dialog :header="'Gestion y Razon'" v-model:visible="modalVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 20%; min-height: 20vh">
                <p class="modal-card-title">Ultimo Paso</p>
                    
                <p>{{razon_creada}}<buttonp class="button is-warning" @click.prevent="copiarRazon()">Copiar</buttonp></p>               
                <p>{{gestion_creada}}<buttonp class="button is-warning" @click.prevent="copiarGestion()">Copiar</buttonp></p>
 
            </div>

             <template #footer>
                <buttonp label="Cerrar Gestion" icon="pi pi-times" @click="goTo('general-managment-control')" autofocus />
                <buttonp label="Cerrar obtener otro" icon="pi pi-times" @click="clearForm()" autofocus />
            </template>
        </Dialog>

        
    <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    
    import ManagmentControlService from '@/service/ManagmentControlService';
    import EmployeeService from '@/service/EmployeeService';
    import VehicleService from '@/service/VehicleService';

    import {mapGetters} from 'vuex';
    


    let NewManagmentControl = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {
            this.getAllColaborator();
            this.getAllVehicle();
            this.getAllTypes();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               selectedVehicle: null,
               selectedDriver: null,
               selectedSupervisor: null,
               all_colaboratrs: [],
               all_vehicle: [],
               filteredSupervisorMultiple: [],
               filteredVehicleMultiple: [],
               filteredDriverMultiple: [],
               types:[], 
               subtypes: [],
               obs1: [],
               obs2: [],
               obs3: [],
               selectedType: null,
               selectedSubtype: null,
               selectedObsOne: null,
               selectedObsTwo: null,
               selectedObsThree: null,
               event_date: null,
               is_efective: false,
               is_discard: false,
               modalVisible: false, 
               razon_creada: null,
               gestion_creada: null,
            }
        };
        this.methods = {
            clearForm(){
                this.selectedVehicle= null;
                this.selectedDriver= null;
                this.selectedSupervisor= null;
                this.selectedType= null;
                this.selectedSubtype=null;
                this.selectedObsOne=null;
                this.selectedObsTwo= null;
                this.selectedObsThree= null;
                this.event_date=null;
                this.is_efective= false;
                this.is_discard= false;
                this.modalVisible= false; 
                this.razon_creada= null;
                this.gestion_creada= null;
            },
            async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Copiado'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
            copiarGestion(){
                this.copyURL(this.gestion_creada);
            },  
            copiarRazon(){
                this.copyURL(this.razon_creada);
            },
            createNew(){
                var mc = {'event_date': null, 'supervisor_id': null, 'driver_id':null, 'vehicle_id':null, 'reason':null, 'managment':null, 'type_control_id':null, 'sub_type_control_id':null, 'observation_one_id':null, 'observation_two_id':null, 'observation_three_id':null}
                mc.event_date = this.event_date
                mc.supervisor_id = this.selectedSupervisor.employee_id;
                mc.driver_id = this.selectedDriver.employee_id;
                mc.vehicle_id = this.selectedVehicle.vehicle_id;
                mc.reason =  this.selectedObsOne.name +'-'+this.selectedObsTwo.name+'-'+this.selectedObsThree.name;
                mc.management = this.selectedType.name+'-'+this.selectedSubtype.name;
                mc.type_control_id = this.selectedType.type_control_id;
                mc.sub_type_control_id = this.selectedSubtype.sub_type_control_id;
                mc.observation_one_id =  this.selectedObsOne.observation_one_id;
                mc.observation_two_id =  this.selectedObsTwo.observation_two_id;
                mc.observation_three_id = this.selectedObsThree.observation_three_id;

                ManagmentControlService.new(mc).then((response) => {
                    this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                    this.razon_creada = this.selectedObsOne.name +'-'+this.selectedObsTwo.name+'-'+this.selectedObsThree.name;
                    this.gestion_creada = this.selectedType.name+'-'+this.selectedSubtype.name;
                    this.modalVisible = true;
                    //this.goTo('home');
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },
            searchSupervisorMultiple(event){
                var filteredResultDrivers = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_colaboratrs.length; i++) {
                        if (this.all_colaboratrs[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.all_colaboratrs[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultDrivers.push(this.all_colaboratrs[i])
                        }
                    }
                    this.filteredSupervisorMultiple = filteredResultDrivers;
                } else {
                    return [];
                }   
            },

            searchDriversMultiple(event){
                var filteredResultDrivers = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_colaboratrs.length; i++) {
                        if (this.all_colaboratrs[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.all_colaboratrs[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultDrivers.push(this.all_colaboratrs[i])
                        }
                    }
                    this.filteredDriverMultiple = filteredResultDrivers;
                } else {
                    return [];
                }   
            },
            searchVehicleMultiple(event){
                var filteredResultVehicle = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_vehicle.length; i++) {
                        if (this.all_vehicle[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())  
                        ) {
                            filteredResultVehicle.push(this.all_vehicle[i])
                        }
                    }
                    this.filteredVehicleMultiple = filteredResultVehicle;
                } else {
                    return [];
                }   
            },
            getAllTypes(){
                ManagmentControlService.getAllTypes().then((response) => {
                        this.types = response.data.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getSubTypeByType(type_id){
                 ManagmentControlService.gettAllSubTypebyType(type_id).then((response) => {
                        this.subtypes = response.data.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getObsOne(subtype_id){
                ManagmentControlService.gettAllObsOne(this.selectedType.type_control_id, subtype_id).then((response) => {
                        this.obs1 = response.data.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getObsTwo(obsone_id){
                ManagmentControlService.gettAllObsTwo(this.selectedType.type_control_id, this.selectedSubtype.sub_type_control_id, obsone_id).then((response) => {
                        this.obs2 = response.data.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getObsThree(obs2_id){
                console.log(obs2_id);
                var efe = false
                if(this.is_efective == true)
                    efe = true
                if(this.is_discard == true)
                    efe = false
                ManagmentControlService.gettAllObsThree(this.selectedType.type_control_id, this.selectedSubtype.sub_type_control_id, this.selectedObsOne.observation_one_id ,obs2_id, efe).then((response) => {
                        this.obs3 = response.data.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllColaborator(){
                 EmployeeService.getAll().then((response) => {
                        this.all_colaboratrs= response.data;
                        for (var i = 0; i < this.all_colaboratrs.length; i++) {
                            this.all_colaboratrs[i]['first_name1'] = this.all_colaboratrs[i]['first_name1'] +" "+this.all_colaboratrs[i]['first_name2']+' '+this.all_colaboratrs[i]['last_name1'] + ' ' +this.all_colaboratrs[i]['last_name2']+'('+this.all_colaboratrs[i]['card_id'] +'-'+ this.all_colaboratrs[i]['check_digit']+')';
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            
            getAllVehicle(){
                 VehicleService.getAll().then((response) => {
                        this.all_vehicle= response.data;
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups',
                error: 'form/error-states'
            })
        };
        this.watch = {
            selectedType(newvalue){
                if(newvalue){
                    this.selectedSubtype = null;
                    this.selectedObsOne = null;
                    this.selectedObsTwo = null;
                    this.selectedObsThree = null;
                    this.getSubTypeByType(newvalue.type_control_id);
                }
            },
             selectedSubtype(newvalue){
                if(newvalue){
                    this.selectedObsOne = null;
                    this.selectedObsTwo = null;
                    this.selectedObsThree = null;
                    this.getObsOne(newvalue.sub_type_control_id);
                }
            },
            selectedObsOne(newvalue){
                if(newvalue){
                    this.selectedObsTwo = null;
                    this.selectedObsThree = null;
                    this.getObsTwo(newvalue.observation_one_id);
                }
            },
            selectedObsTwo(newvalue){
                if(newvalue){
                    this.selectedObsThree = null;
                    if(this.is_efective == true || this.is_discard == true)
                        this.getObsThree(newvalue.observation_two_id);
                }
            },
            is_discard(newvalue){
                if(newvalue){
                    this.getObsThree(this.selectedObsTwo.observation_two_id);
                }
            },
            is_efective(newvalue){
                if(newvalue){
                    this.getObsThree(this.selectedObsTwo.observation_two_id);
                }
            }
        };

        this.props = [];
    }).apply(NewManagmentControl);

    export default NewManagmentControl
</script>
